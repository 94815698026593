<template>
	<router-view/>
								
	<transition name="fade">
		<div class="loader" v-if="loading">
			<div class="loading">
				<img class="logo" src="@/assets/logo.png"/>
			</div>
		</div>
	</transition>
</template>

<script>
	import { helperMixin } from '@/store/helper.js';
	import axios from 'axios';
	
	export default {
		mixins : [helperMixin],
		data() {
			return {
				loading : true,
			};
		},
		mounted(){
			var match_id = localStorage.getItem('match_id');
			var user_id = localStorage.getItem('user_id');
			
			if(match_id == null || user_id == null){
				//Senza nessun dato in localstorage reindirizzo sempre alla home
				this.$router.replace({ path: '/' })
				
				setTimeout(function(){
					this.loading = false;
				}.bind(this), 750)
			}else{
				//Se ho dei dati salvati cerco di riconnettermi al match
				axios.get(this.api_url+'reconnect?match_id='+match_id+'&user_id='+user_id)
					.then(function (response) {
						if(response.status == 200 && response.data.success){
						
							this.$store.commit('reconnectMatch', {
								match_id : match_id,
								user_id : user_id,
								stage : response.data.message.match.stage,
								code : response.data.message.match.code,
								user_name : response.data.message.user.name,
								superuser : response.data.message.user.superuser == 1,
								status : response.data.message.user.status,
							});
							
							switch(response.data.message.match.stage){
								case 'matchmaking':
									this.$router.replace({ path: '/lobby' });
								break;
								default:
									this.$router.replace({ path: '/game' });
								break;
							}
							
							if(response.data.message.data.disaster){
								this.$store.commit('setDisaster', response.data.message.data.disaster);
							}
						
						}else{
							this.$store.commit('resetMatch');
							this.$router.replace({ path: '/' })
						}
						
						setTimeout(function(){
							this.loading = false;
						}.bind(this), 750)
						
					}.bind(this));
			}
			this.$store.dispatch('startPolling');
			
			setInterval(() => this.$store.commit('setCurrentTime'), 1000);
		}
	};
</script>

<style lang="scss">

	@font-face {
		font-family: "MachoMoustache";
		font-weight: normal;
		src: 
			url(./assets/fonts/MachoMoustache-Regular_W.woff)  format("woff"),
			url(./assets/fonts/MachoMoustache-Regular_W.woff2) format("woff2");
	}

	@font-face {
		font-family: "MachoMoustache";
		font-weight: bold;
		src: 
			url(./assets/fonts/MachoMoustache-Bold_W.woff)  format("woff"),
			url(./assets/fonts/MachoMoustache-Bold_W.woff2) format("woff2");
	}

	@font-face {
		font-family: "Fulmar";
		font-weight: normal;
		src: url(./assets/fonts/Fulmar-Regular_W.woff)  format("woff");
	}

	@font-face {
		font-family: "Fulmar";
		font-weight: bold;
		src: url(./assets/fonts/Fulmar-Bold_W.woff)  format("woff");
	}

	@font-face {
		font-family: "RotundaVeneta";
		font-weight: normal;
		src: url(./assets/fonts/RotundaVeneta.woff2)  format("woff2");
	}
	
	@font-face {
		font-family: 'SoleSerif';
		src: 
			url(./assets/fonts/SoleSmallVF_W.woff)  format("woff"),
			url(./assets/fonts/SoleSmallVF_W.woff2) format("woff2");
		font-weight: 1 1000;
	}

	@import "./assets/styles/variables.scss";

	* {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		-webkit-text-size-adjust: 100%;
	}
	
	html{
		font-family: 'MachoMoustache', sans-serif;
		font-size: 16px;
	}
	
	.main-column{
		position: relative;
		width: 90%;
		max-width: 350px;
		margin: 0 auto;
	}
	
	h1{
		font-weight: normal;
	}
	
	.flex{
		display: flex;
	}
	.flex.space{
		justify-content: space-between;
	}
	.flex.center{
		justify-content: center;
	}
	.flex.v-center {
		align-items: center;
	}
	.flex.h-center {
		justify-content: center;
	}
	.flex.h-end {
		justify-content: flex-end;
	}
	.text-center{
		text-align: center;
	}
	.text-right{
		text-align: right;
	}
	.uppercase{
		text-transform: uppercase;
	}
	.bold{
		font-weight: bold;
	}
	
	.row-padding{
		padding-top: 25px;
		padding-bottom: 25px;
	}
	.col-padding{
		padding-left: 25px;
		padding-right: 25px;
	}
	.bottom-padding{
		padding-bottom: 25px;
	}
	.small-row-padding{
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.small-col-padding{
		padding-left: 10px;
		padding-right: 10px;
	}
	.small-bottom-padding{
		padding-bottom: 10px;
	}

	.button{
		display: flex;
		justify-content: center;
		align-items: center;
		background: $red;
		color: $white;
		text-transform: uppercase;
		padding: 11px 25px;
		border-radius: 15px;
		cursor: pointer;
		opacity: 1;
		transition: opacity 0.2s;
	}
	.button.arrow{
		justify-content: space-between;
	}
	.button.arrow:after{
		content: '';
		width: 27px;
		height: 9px;
		background: url('~@/assets/arrow.png');
	}
	.button.loading,
	.button.disabled{
		opacity: 0.5;
		pointer-events: none;
	}
	.button.white{
		background-color: white;
		color: $red;
	}
	.button.white.arrow:after{
		background: url('~@/assets/arrowred.png');
	}
	
	input[type=text]{
		border: 2px solid $red;
		padding: 10px 25px;
		border-radius: 15px;
		height: 40px;
	}
	
	hr{
		border: none;
		border-bottom: 2px solid black;
		margin: 10px 0;
	}
	
	select{
		width: 100%;
		text-transform: uppercase;
		background-color: transparent;
		border: 1px solid #DADADA;
		padding: 5px;
		border-radius: 10px;
		cursor: pointer;
	}
	
	/* TYPOGRAPHY */
	
	.fulmar{
		font-family: 'Fulmar', serif;
	}
	.sole-serif{
		font-family: 'SoleSerif', serif;
		font-variation-settings: "opsz" 1000;
	}
	.macho-moustache{
		font-family: 'MachoMoustache', sans-serif;
	}
	.rotunda-veneta{
		font-family: 'RotundaVeneta', serif;
		font-size: 40px;
		white-space: nowrap;
	}
	
	.small-font{
		font-size: 11px;
	}
	.medium-font{
		font-size: 21px;
	}
	.large-font{
		font-size: 35px;
	}
	.huge-font{
		font-size: 80px;
	}
	
	/* LOADER */ 
	
	.loader{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $red;
		z-index: 10;
	}
	.loader .loading{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	.loader .loading .dot{
		width: 7px;
		height: 7px;
		background-color: white;
		border-radius: 50%;
		float: left;
		margin: 0 5px;
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-animation: fx 1000ms ease infinite 0ms;
				animation: fx 1000ms ease infinite 0ms;
	}
	.loader .loading .dot:nth-child(2){
		-webkit-animation: fx 1000ms ease infinite 300ms;
				animation: fx 1000ms ease infinite 300ms;
	}
	.loader .loading .dot:nth-child(3){
		-webkit-animation: fx 1000ms ease infinite 600ms;
				animation: fx 1000ms ease infinite 600ms;
	}
	@-webkit-keyframes fx { 50% { -webkit-transform: scale(1); transform: scale(1); opacity: 1; } 100% { opacity: 0; } }
	@keyframes fx { 50% {  -webkit-transform: scale(1); transform: scale(1); opacity: 1; } 100% { opacity: 0; } }
	
	/* VUE TRANSITION */

	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter-from, .fade-leave-to{
		opacity: 0;
	}
	
	
	@media screen and (max-width: 374px) {
		.rotunda-veneta{
			font-size: 35px;
		}
		.boards{
			font-size: 14px;
		}
	}
	@media screen and (max-width: 339px) {
		.rotunda-veneta{
			font-size: 30px;
		}
		.boards .name img{
			transform: scale(0.7);
			margin-right: 0 !important;
		}
	}
</style>