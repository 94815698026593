import { createStore } from 'vuex'
import axios from 'axios';
import router from './../router'

export default createStore({
	state: {
		api_url : 'https://lifebewarevalley.com/admin/wp-json/game/',
		// api_url : 'http://qzrstudio.com/lbw/wp-json/game/',
		// api_url : 'http://localhost/life-backend/wp-json/game/',
		current_time : Math.floor(Date.now()/1000),
		match_id : 0,
		user_id : 0,
		user_name : '',
		code : '',
		superuser : false,
		stage : '',
		status : '',
		disaster : null,
		
		polling : null,
		users_list : [],
		choice_users : [],
		current_choice : null,
		group : [],
		
		public_board : {
			citizens_wellbeing_perception: 0,
			ecosystem_services: 0,
			hydraulic_risk_reduction_level: 0,
			long_economic_availability: 0,
			short_economic_availability: 0,
		},
		personal_board : {
			citizens_wellbeing_perception: 0,
			ecosystem_services: 0,
			hydraulic_risk_reduction_level: 0,
			long_economic_availability: 0,
			short_economic_availability: 0,
		},
		work_board : {
			citizens_wellbeing_perception: 0,
			ecosystem_services: 0,
			hydraulic_risk_reduction_level: 0,
			long_economic_availability: 0,
			short_economic_availability: 0,
		},
		
		timer : {
			end : null,
			pause : null,
		}
	},
	getters: {
		
	},
	mutations: {
		setCurrentTime(state){
			state.current_time = Math.floor(Date.now()/1000);
		},
		startMatch(state, data){
			state.match_id = data.match_id;
			state.user_id = data.user_id;
			state.code = data.code;
			state.stage = 'matchmaking';
			state.superuser = true;
			
			localStorage.setItem('match_id', data.match_id);
			localStorage.setItem('user_id', data.user_id);
		},
		joinMatch(state, data){
			state.match_id = data.match_id;
			state.user_id = data.user_id;
			state.stage = 'matchmaking';
			state.superuser = false;
			state.status = 'to_be_accepted';
			
			localStorage.setItem('match_id', data.match_id);
			localStorage.setItem('user_id', data.user_id);
		},
		reconnectMatch(state, data){
			state.match_id = data.match_id;
			state.user_id = data.user_id;
			state.user_name = data.user_name;
			state.stage = data.stage;
			state.superuser = data.superuser;
			state.status = data.status;
			state.code = data.code;
		},
		resetMatch(){
			localStorage.removeItem('match_id');
			localStorage.removeItem('user_id');
		},
		setName(state, name){
			state.user_name = name;
		},
		setStage(state, stage){
			state.stage = stage;
		},
		setStatus(state, status){
			state.status = status;
		},
		setDisaster(state, disaster){
			state.disaster = disaster;
		},
		setTimerEnd(state, end){
			state.timer.end = end;
		},
		setTimerPause(state, pause){
			state.timer.pause = pause;
		},
		setCurrentChoice(state, choice){
			state.current_choice.choice_data.choice = choice;
		},
	},
	actions: {
		startPolling(context) {
			if(context.state.polling == null){
				
				context.state.polling = setInterval(() => {
					if(context.state.match_id != 0 && context.state.user_id != 0){
						
						if(context.state.superuser){
							//Polling admin
							axios.get(context.state.api_url+'admin_game_status?match_id='+context.state.match_id+'&user_id='+context.state.user_id+'&t='+Date.now())
								.then(function (response) {	
									if(response.status == 200 && response.data.success){
										context.state.public_board = response.data.message.values.public;
										context.state.timer = response.data.message.timer;
										
										switch(response.data.message.stage){
											case 'matchmaking':
												context.state.users_list = response.data.message.data;
											break;
											case 'first_turn_personal':
											case 'second_turn_personal':
											case 'third_turn_personal':
											case 'fourth_turn_personal':
											case 'first_turn_work':
											case 'third_turn_work':
											case 'second_turn_publicvote':
											case 'second_turn_publicdiscuss':
											case 'second_turn_publicview':
											case 'fourth_turn_publicvote':
											case 'fourth_turn_publicdiscuss':
											case 'fourth_turn_publicview':
												context.state.choice_users = response.data.message.data;
											break;
										}
									}
								});
						}else{
							//Polling user
							axios.get(context.state.api_url+'user_game_status?match_id='+context.state.match_id+'&user_id='+context.state.user_id+'&t='+Date.now())
								.then(function (response) {
									if(response.status == 200 && response.data.success){
										
										context.state.public_board = response.data.message.values.public;
										context.state.personal_board = response.data.message.values.personal;
										context.state.work_board = response.data.message.values.work;
										context.state.timer = response.data.message.timer;
										
										switch(response.data.message.stage){
											case 'matchmaking':
												context.commit('setStatus', response.data.message.data);
											break;
											case 'beginning':
												if(context.state.stage == 'matchmaking'){
													router.replace({ path: '/game' });
												}
											break;
											case 'first_disaster':
											case 'last_disaster':
												context.commit('setDisaster', response.data.message.data);
											break;
											case 'first_turn_prework':
											case 'second_turn_prepublic':
											case 'third_turn_prework':
											case 'fourth_turn_prepublic':
												context.state.current_choice = null;
												context.state.group = response.data.message.data;
											break;
											case 'first_turn_personal':
											case 'second_turn_personal':
											case 'third_turn_personal':
											case 'fourth_turn_personal':
											case 'first_turn_work':
											case 'second_turn_work':
											case 'third_turn_work':
											case 'second_turn_publicvote':
											case 'second_turn_publicdiscuss':
											case 'second_turn_publicview':
											case 'fourth_turn_publicvote':
											case 'fourth_turn_publicdiscuss':
											case 'fourth_turn_publicview':
												context.state.current_choice = response.data.message.data;
											break;
											case 'first_turn_end':
											case 'second_turn_end':
											case 'third_turn_end':
											case 'fourthturn_end':
												context.state.current_choice = null;
											break;
										}
										
										//Cambio di stage
										if(context.state.stage != response.data.message.stage){
											context.commit('setStage', response.data.message.stage);
										}
									}
								});
						}
						
					}	
				}, 3000);	
				
			}
		},
		startGame(context){
			context.commit('setStage', 'beginning');
		}
	},
	modules: {
		
	}
})
