import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Matchmaking',
		component: () => import( '../views/Matchmaking.vue' )
	},
	{
		path: '/lobby',
		name: 'Lobby',
		component: () => import( '../views/Lobby.vue' )
	},
	{
		path: '/game',
		name: 'Game',
		component: () => import( '../views/Game.vue' )
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
