import { mapState } from 'vuex'

export const helperMixin = {
	computed: mapState([
		'api_url', 'current_time', 'code', 'match_id', 'user_id', 'user_name', 'superuser', 'stage', 'status', 
		'users_list', 'disaster', 'choice_users', 'current_choice', 'group', 'public_board', 'personal_board', 'work_board', 
		'timer', 
	]),
	methods : {
		boardValueName(key){
			switch(key){
				case 'citizens_wellbeing_perception':
					return 'Benessere dei cittadini';
				case 'ecosystem_services':
					return 'Servizi ecosistemici';
				case 'hydraulic_risk_reduction_level':
					return 'Riduzione rischio idraulico';
				case 'long_economic_availability':
					return 'Risorse economiche a lungo termine';
				case 'short_economic_availability':
					return 'Risorse economiche a breve termine';
			}
		},
		boardValueNameWithArticle(key){
			switch(key){
				case 'citizens_wellbeing_perception':
					return 'il <strong>Benessere dei cittadini</strong>';
				case 'ecosystem_services':
					return 'i Servizi ecosistemici';
				case 'hydraulic_risk_reduction_level':
					return 'la Riduzione rischio idraulico';
				case 'long_economic_availability':
					return 'le Risorse economiche a lungo termine';
				case 'short_economic_availability':
					return 'le Risorse economiche a breve termine';
			}
		},
		boardLevelName(level){
			switch(level){
				case 'none':
					return 'Non influente';
				case 'very-low':
					return 'Molto basso';
				case 'low':
					return 'Basso';
				case 'medium-low':
					return 'Medio-basso';
				case 'medium':
					return 'Medio';
				case 'medium-high':
					return 'Medio-alto';
				case 'high':
					return 'Alto';
				case 'very-high':
					return 'Molto alto';
			}
		},
		readableTime(timestamp){
			var minutes = Math.floor(timestamp/60);
			var seconds = timestamp%60;
			return minutes+':'+seconds.toString().padStart(2, '0');
		}
	}
}